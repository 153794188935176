.PolarisBoardManagement {
  padding: 6rem 0;
  background: #f6f6f6;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  overflow: hidden;

  .polaris-board-management {
    max-width: 1280px;
    margin: 0 auto;

    h2 {
      font-size: 2.3rem;
      font-weight: 500;
      text-align: center;
      color: #2b4ea2;
    }

    .management-list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4rem 0;

      .management-item {
        margin: 0 4rem;
        text-align: center;

        .management-img {
          line-height: 240px;
          img {
            vertical-align: middle;
          }
        }
        p {
          margin-top: 2rem;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
  }
}
