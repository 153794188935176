.PolarisBoardMirroring {
  padding: 6rem 0;
  .polaris-board-mirroring {
    h2 {
      font-size: 2.3rem;
      font-weight: 500;
      color: #2b4ea2;
      text-align: center;
    }

    .mirroring-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 6rem 0;
      .mirroring-item {
        flex: 1 1;
        margin: 0 2rem;
        text-align: center;
        p {
          margin-bottom: 2rem;
          font-size: 1.25rem;
        }
      }
    }

    p {
      text-align: center;
      font-size: 1.25rem;
    }
  }
}
