.PolarisBoardConfig {
  padding: 6rem 0;
  background: #f6f6f6;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  overflow: hidden;
  .polaris-board-config {
    max-width: 1280px;
    margin: 0 auto;

    h2 {
      font-size: 2.3rem;
      font-weight: 500;
      text-align: center;
      color: #2b4ea2;
    }

    .polaris-board-config-img {
      text-align: center;
      padding: 4rem 0;
    }

    .polaris-board-config-text {
      display: flex;
      justify-content: center;
      align-items: c;
      list-style: none;
      li {
        font-size: 1.25rem;
        margin: 0 4rem;

        &:before {
          content: '·';
          font-size: 40px;
          vertical-align: sub;
          padding-right: 0.25rem;
        }
      }
    }
  }
}
