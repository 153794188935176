.Header {
  width: 100%;
  background: #2b4ea2;
  padding: 1rem 0;

  .header-wrap {
    max-width: 1280px;
    margin: 0 auto;

    .header-logo {
      display: inline-block;
      cursor: pointer;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
