.PolarisBoardXSchool {
  padding: 5rem 0;
  .polaris-board-and-school {
    .title-wrap {
      text-align: center;
      line-height: 1.4;
      h2 {
        font-size: 2.3rem;
        font-weight: 500;
        color: #2b4ea2;
      }
      p {
        font-size: 1.25rem;
        color: #363636;
      }
    }

    .school-contents {
      padding: 3rem 0;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      justify-content: center;

      .school-content {
        position: relative;
        margin: 2rem;
        cursor: pointer;

        img {
          border-radius: 12px;
        }

        .hover-box {
          background: #000;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          opacity: 0;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          transition: 0.2s opacity;

          .hover-text {
            word-break: keep-all;
            line-height: 1.5;
            padding: 0 2rem;
          }
        }

        &:hover {
          .hover-box {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
