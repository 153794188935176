.SideNavigation {
  display: inline-block;
  position: fixed;
  top: 20rem;
  right: 2rem;
  z-index: 9999;
  img {
    cursor: pointer;
  }
}
