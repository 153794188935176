.PolarisBoardOperationSystems {
  padding: 6rem 0;
  background: #f6f6f6;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  overflow: hidden;

  .polaris-board-os {
    max-width: 1280px;
    margin: 0 auto;

    h2 {
      font-size: 2.3rem;
      font-weight: 500;
      text-align: center;
      color: #2b4ea2;
    }

    .platform-imgs {
      display: flex;
      align-items: center;
      vertical-align: middle;
      justify-content: center;
      padding: 5rem 0;

      .platform-img {
        margin: 0 2rem;

        img {
          width: 100%;
        }
      }
    }

    p {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      color: #363636;
    }
  }
}
