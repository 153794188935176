.Footer {
  width: 100%;
  position: relative;
  background: #f8f8f8;

  .footer-wrap {
    max-width: 1280px;
    margin: 0 auto;
    padding: 3rem 0;

    .footer-box {
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .gp-logo {
        flex: 0.2 1;
        text-align: center;
        padding: 50px 0px;
      }

      .footer-center {
        flex: 0.8 1;

        .footer-center-text {
          padding: 0 4rem;
          line-height: 1.5;
        }

        .footer-center-bottom {
          font-size: 0.8rem;
          color: #555555;
          font-weight: 500;

          div {
            span {
              margin-right: 10px;
            }
          }

          .copyrights {
            margin-top: 25px;
            font-size: 0.7rem;
          }
        }
      }

      .footer-right {
        flex: 0.3 1;
        list-style: none;
        line-height: 1.2;
        color: #555555;

        .footer-cs {
          font-size: 1rem;
          font-weight: 500;
        }

        .footer-company-ph {
          margin-top: 0.2rem;
          font-size: 1.5rem;
          font-weight: 500;
        }

        .footer-company-wh {
          margin-top: 0.5rem;
          font-size: 0.7rem;
        }

        .footer-company-sns {
          margin-top: 15px;

          ul {
            display: flex;

            li {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .footer-bottom-sns {
      padding: 3rem 0;
      display: none;

      ul {
        padding: 0 2rem;
        display: flex;
        float: right;

        li {
          padding: 0 0.3em;
        }
      }
    }
  }
}
