.PolarisBoardIntroduction {
  padding: 6rem 0;
  background: #fff;
  .polaris-board-introduction {
    display: flex;
    align-items: center;
    .introduction-img {
      flex: 1 1;
      img {
        width: 100%;
      }
    }

    .introduction-txt {
      flex: 1 1;
      text-align: left;

      h1 {
        font-size: 2.8rem;
        margin: 2rem 0;
        font-weight: 500;
      }

      p {
        font-size: 1.25rem;
        word-break: keep-all;
        line-height: 1.2;
        padding-right: 2rem;
      }
    }
  }
}
