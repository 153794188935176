.PolarisBoardDesc {
  padding: 4rem 0;

  .polaris-board-desc {
    .desc-list {
      display: flex;
      .desc-item {
        text-align: center;
        word-break: keep-all;
        padding: 3rem;
        width: 25%;

        .desc-img {
          line-height: 135px;
          img {
            vertical-align: middle;
          }
        }

        .desc-title {
          margin-top: 1.5rem;
          font-size: 1.25rem;
          font-weight: 500;
        }
        .desc-sub-title {
          font-size: 1.15rem;
          color: #363636;
          margin-top: 1.25rem;
          padding: 0 1.25rem;
          font-weight: 400;
        }
      }
      .blue {
        background: #2b4ea2;
        p {
          color: #fff !important;
        }
      }
    }
  }
}
